<template>
  <view-item title="配件出库">
    <template #operation>
    
      <en-button @click="operation.add.click" type="primary">创建单据</en-button>
      <button-ajax v-if="form.data.id" :disabled="form.disabled" type="primary" :method="operation.save.click">保存</button-ajax>
      <button-ajax
        v-if="(form.data.id && form.data.status?.code === 'A') || form.data.status?.code === 'S'"
        type="primary"
        :method="operation.reverse.click"
        >退单</button-ajax
      >
      <button-ajax v-if="form.data.id && code === 'SAL'" :disabled="form.disabled" type="primary" :method="operation.commit.click"
        >保存并出库</button-ajax
      ><button-ajax v-if="form.data.id && code === 'SALR'" :disabled="form.disabled" type="primary" :method="operation.commit.click"
        >保存并退货</button-ajax
      >
      <button-ajax v-if="form.data.id && code === 'OTH'" :disabled="form.disabled" type="primary" :method="operation.commit.click"
        >保存并出库</button-ajax
      >

      <en-button v-if="form.data.id" @click="operation.print.click">打印</en-button>
      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button
          >操作<en-icon><ArrowDown /></en-icon
        ></en-button>
        <template #dropdown>
          <en-dropdown-item :disabled="form.disabled" command="detail">{{
            code === 'SAL' ? '编辑销售单' : code === 'SALR' ? '编辑销退单' : code === 'OTH' ? '编辑其他出货单' : '编辑移库出库单'
          }}</en-dropdown-item>
          <en-dropdown-item command="export">导出</en-dropdown-item>
          <en-dropdown-item command="history">单据历史</en-dropdown-item>
          <en-dropdown-item :disabled="form.disabled" command="delete">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        :code="manifest.code"
        :ajax="{
          action: 'GET /enocloud/sale/query',
          params: (params) => (params.payload = { typeCode: code, rsexp: 'id,serialNo,status,client[name]' })
        }"
        :props="{ start: 'startDate', end: 'endDate' }"
        :routes="[
          { path: '/accessory/stock/out/sale', name: '销售单' },
          { path: '/accessory/stock/out/refund', name: '销退单' },
          { path: '/accessory/stock/out/other', name: '其他出货单' },
          { path: '/accessory/stock/out/transfer', name: '移库出库单' }
        ]"
        @row-click="manifest.row.click"
        @expand-click="dialog.visible = true"
        :ref="setRef('manifest')"
      >
        <template #STATUS="{ row }: { row: EnocloudInventoryDefinitions['SaleQueryDto'] }">
          <en-tag :type="row.status?.code === 'S' ? 'success' : row.status?.code === 'P' ? 'warning' : 'danger'">{{ row.status?.message }}</en-tag>
        </template>
        <template #form="{ data }">
          <en-form-item label="单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>

          <en-form-item label="出库日期" v-if="code === 'SAL'">
            <en-date-picker v-model:start="data.outAuditStartDate" v-model:end="data.outAuditEndDate" class="w-full"></en-date-picker>
          </en-form-item>
          <en-form-item label="出库仓库" v-if="code === 'SAL' || code === 'OTH'">
            <select-maintain
              v-model="data.warehouseId"
              :ajax="{
                action: 'GET /enocloud/common/warehouse',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="退库仓库" v-if="code === 'SALR'">
            <select-maintain
              v-model="data.warehouseId"
              :ajax="{
                action: 'GET /enocloud/common/warehouse',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="OE号">
            <en-input v-model="data.goodsOem"></en-input>
          </en-form-item>
          <en-form-item label="编码">
            <en-input v-model="data.goodsSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="配件">
            <en-input v-model="data.goodsName"></en-input>
          </en-form-item>
          <en-form-item label="发票类型" v-if="code === 'SAL' || code === 'SALR'">
            <select-maintain
              v-model="data.invoiceTypeName"
              :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['INVCTP']) }"
              :props="{ label: 'name', value: 'name' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="结算进度" v-if="code === 'SAL' || code === 'SALR'">
            <select-maintain
              v-model="data.settlementProgressCode"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['STLMTPRG']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="结算方式" v-if="code === 'SAL' || code === 'SALR'">
            <select-maintain
              v-model="data.settlingAccountMethodCode"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['STLACMTD']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="制单人">
            <en-input v-model="data.preparedByName"></en-input>
          </en-form-item>

          <en-form-item label="经手人">
            <select-maintain
              v-model="data.auditById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, value) => {
                  params.payload = { name: value }
                }
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <div class="flex gap-4">
          <span>业务选择：{{ code === 'SAL' ? '采购' : code === 'SALR' ? '销退' : code === 'OTH' ? '其他出货单' : '移库出库' }}</span>
          <span>客户：{{ form.data.client?.name }}</span>
        </div>

        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
                <span>{{ form.data.warehouse?.name }}</span>
                <span>{{ form.data.logisticsCompany?.name }}</span>
                <span>{{ form.data.freightPaymentMethod?.message }}</span>

                <en-tag :type="form.data.status?.code === 'S' ? 'success' : form.data.status?.code === 'P' ? 'warning' : 'danger'">{{
                  form.data.status?.message
                }}</en-tag>
                <span>{{ form.data.serialNo }}</span>
                <span>{{ formatDate(form.data.datetime) }}</span>
              </div>
            </template>

            <en-form label-position="right" class="grid grid-cols-3">
              <en-form-item label="结算方式" v-if="code === 'SAL' || code === 'SALR'">{{ form.data.settlingAccountMethod?.message }}</en-form-item>
              <en-form-item label="出库类型" v-if="code === 'OTH'">{{ form.data?.otherType }}</en-form-item>
              <en-form-item label="制单人">{{ form.data.preparedBy?.name }}</en-form-item>
              <en-form-item label="经手人">{{ form.data.auditBy?.name }}</en-form-item>
              <en-form-item label="门店">{{ form.data.branch?.shortName }}</en-form-item>
              <en-form-item label="备注">{{ form.data.comment }}</en-form-item>
            </en-form>
          </en-collapse-item>
        </en-collapse>

        <div class="flex items-center gap-6 text-xs text-neutral-500">
          <div class="flex flex-col items-center gap-2">
            <span>总数量</span>
            <span>{{ formatNumber(form.summary?.count) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2" v-if="code === 'SALR' || code === 'SAL'">
            <span>货款金额</span>
            <span>{{ formatMoney(form.summary?.primeCost) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2" v-if="code === 'SAL'">
            <span>折扣金额</span>
            <span>{{ formatMoney(form.summary?.discountRate) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2" v-if="code === 'SAL'">
            <span>折扣后总金额</span>
            <span>{{ formatNumber(form.summary?.count) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2" v-if="code === 'SAL'">
            <span>总成本合计</span>
            <span>{{ formatNumber(form.summary?.count) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2" v-if="code === 'SALR'">
            <span>总金额</span>
            <span>{{ formatMoney(form.data.freight + form.data.totalAmount + form.data.otherCharge) }}</span>
          </div>
        </div>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane
                label="配件"
                name="goods"
                :badge="form.data.saleGoods?.length"
                :style="{ height: height - 55 + 'px', overflow: 'auto' }"
                class="gap-6"
              >
                <div class="flex gap-4">
                  <select-maintain
                    :ajax="{
                      action: 'GET /enocloud/common/goods',
                      params: (params, value) => (params.payload = { name: value, statusCode: 'A' })
                    }"
                    :props="{ label: 'name', value: '' }"
                    value-key="id"
                    placeholder="请选择配件"
                    remote
                    :disabled="form.disabled"
                    @change="form.saleGoods.add.change"
                    class="w-110"
                    v-if="code !== 'SALR'"
                  ></select-maintain>
                  <en-button type="primary" v-if="code !== 'SALR'" :disabled="form.disabled" @click="form.saleGoods.batch.click"
                    >批量添加配件</en-button
                  >
                  <en-button type="primary" v-if="code === 'SALR'" :disabled="form.disabled" @click="form.saleGoods.batch.click"
                    >选择单据配件</en-button
                  >
                </div>

                <flex-box>
                  <template #default="{ height }">
                    <table-dynamic :code="detailManifest.code" :data="form.data.saleGoods" :height="height" show-selectable>
                      <template #OPERATION="{ row, $index }: { row: EnocloudInventoryDefinitions['SaleGoodsDto']; $index: number }">
                        <en-button type="primary" link :disabled="form.disabled" @click="form.saleGoods.delete.click($index)">删除</en-button>
                      </template>
                      <template #GOODS_SERIAL_NO="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">{{ row.goods?.serialNo }}</template>
                      <template #GOODS_NAME="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">{{ row.goods?.name }}</template>
                      <template #GOODS_OEM="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">{{ row.goods?.oem }}</template>
                      <template #WAREHOUSE="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">{{
                        row.batch?.warehouse?.name
                      }}</template>
                      <template #GOODS_VEHICLE_SPEC="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">{{
                        row.goods?.primaryVehicleSpec?.join(',')
                      }}</template>

                      <template #GOODS_BRAND="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">{{ row.goods?.brand }}</template>
                      <template #AMOUNT_AFTER_DISCOUNT="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">
                        <en-input-number
                          :model-value="calculator.mul(row.count, row.price, row.discountRate)"
                          :min="0"
                          :precision="6"
                          class="w-full"
                          :disabled="form.disabled"
                          @change="form.saleGoods.amount.change($event, row)"
                        ></en-input-number>
                      </template>
                      <template #GOODS_PLACE_OF_PRODUCTION="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">{{
                        row.goods?.placeOfProduction
                      }}</template>

                      <template #COUNT="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">
                        <en-input-number v-model="row.count" :min="0" :disabled="form.disabled" class="w-full"></en-input-number>
                      </template>

                      <template #SEQ="{ $index }: { $index: number }">{{ $index + 1 }}</template>
                      <template #PRICE="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">
                        <select-maintain
                          v-model="row.price"
                          :ajax="{
                            action: 'GET /enocloud/common/goods/specification/:specificationId/price',
                            params: (params) => (params.paths = [row.goodsSpecification?.id])
                          }"
                          :props="{ label: 'price', value: 'price' }"
                          allow-create
                          lazy
                          :disabled="!row.goodsSpecification?.id || form.disabled"
                          @change="form.saleGoods.price.change(row)"
                        >
                          <template #default="{ option }: { option: EnocloudCommonDefinitions['PriceDto'] }">
                            <div class="flex justify-between gap-6">
                              <span>{{ option.price }}</span>
                              <span class="text-neutral-500">{{ option.type?.message }}</span>
                            </div>
                          </template>
                        </select-maintain>
                      </template>
                    

                      <template #AMOUNT="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">
                        <en-input-number
                          :model-value="calculator.mul(row.count, row.price)"
                          :min="0"
                          :precision="6"
                          class="w-full"
                          :disabled="form.disabled"
                          @change="form.saleGoods.amount.change($event, row)"
                        ></en-input-number>
                      </template>

                      <template #DISCOUNT_RATE="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">
                        <en-input-rate v-model="row.discountRate" :min="0" class="w-full"></en-input-rate>
                      </template>
                      <template #INVENTORY="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">
                        {{ row.batch?.count }}
                      </template>
                      <template #GOODS_SPECIFICATION_WEIGHT="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">
                        {{ `${row.goodsSpecification?.weight}${row.goods?.warehouseUnit}` }}
                      </template>
                      <template #PRIME_COST="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">
                        {{ formatMoney(row.batch?.primeCost + row.goodsSpecification?.weight) }}
                      </template>

                      <template #GOODS_SPECIFICATION_NAME="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">
                        <select-maintain
                          v-model="row.goodsSpecification"
                          :options="row.goods.specifications"
                          :props="{ label: 'name', value: '' }"
                          :disabled="form.disabled"
                          value-key="id"
                          class="w-full"
                        ></select-maintain>
                      </template>
                      <template #BARCODE="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">
                        {{ row.goods?.barcode }}
                      </template>
                      <template #COMMENT="{ row }: { row: EnocloudInventoryDefinitions['SaleGoodsDto'] }">
                        <en-input v-model="row.goods.comment"></en-input>
                      </template>
                    </table-dynamic>
                  </template>
                </flex-box>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <sale-dialog v-model="dialog.visible" :code="code" :data="form.data"></sale-dialog>

  <sale-detail v-model="detail.visible" :data="form.data" :code="code"></sale-detail>

  <inventory-goods-select v-model="select.visible" type="sale" @confirm="select.confirm"></inventory-goods-select>

  <sale-history v-model="history.visible" :data="form.data" :code="code"></sale-history>

  <goods-price v-model="price.visible" :data="price.data" :specification="price.specification"></goods-price>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es'
import { GoodsPrice, InventoryGoodsSelect, type GoodsSelectionOption } from '@enocloud/business'
import { EnMessageBox } from '@enocloud/components'
import { calculator } from '@enocloud/utils'
import { Purchase, print } from '@enocloud/print'
import SaleDialog from '@accessory/components/sale-dialog.vue'
import SaleDetail from '@accessory/components/sale-detail.vue'

import SaleHistory from '@accessory/components/sale-history.vue'
import { ArrowDown } from '@element-plus/icons-vue'

const PricePanel = factory({
  props: {
    specificationId: Number
  },

  watch: {
    specificationId: {
      immediate: true,
      handler() {
        if (this.specificationId) this.table.get()
      }
    }
  },

  config: {
    children: {
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/goods/specification/:specificationId/price',
            data: 'array',
            loading: true,
            params(params) {
              params.paths = [this.specificationId]
            }
          }
        },
        computed: {
          purchasePrice() {
            return this.table.data.find((item) => item.type?.code === 'RPP')
          },
          salePrice() {
            return this.table.data.find((item) => item.type?.code === 'RMP')
          }
        }
      }
    }
  },

  render(h) {
    return h('div', { class: 'flex gap-2' }, [
      this.store.accessRightsHash.PRICE_PURCHASE &&
        h('div', { class: 'flex flex-col' }, [
          h('span', `进价：${this.table.purchasePrice?.price ?? '-'}`),
          h('span', `供应商：${this.table.purchasePrice?.customerName ?? '-'}`),
          h('span', `采购日期：${this.table.purchasePrice?.documentDate ?? '-'}`),
          h('span', `采购单号：${this.table.purchasePrice?.documentSerialNo ?? '-'}`)
        ]),
      this.store.accessRightsHash.PRICE_SERVICE &&
        h('div', { class: 'flex flex-col' }, [
          h('span', `进价：${this.table.purchasePrice?.price ?? '-'}`),
          h('span', `客户：${this.table.purchasePrice?.customerName ?? '-'}`),
          h('span', `销售日期：${this.table.purchasePrice?.documentDate ?? '-'}`),
          h('span', `工单单号：${this.table.purchasePrice?.documentSerialNo ?? '-'}`)
        ])
    ])
  }
})

export default factory({
  props: {
    code: {
      type: String as PropType<'SAL' | 'SALR' | 'OTH' | 'TRA'>,
      default: 'SAL'
    }
  },

  components: {
    SaleDialog,
    SaleDetail,
    GoodsPrice,
    PricePanel,
    SaleHistory,
    ArrowDown,
    InventoryGoodsSelect
  },

  config: {
    children: {
      operation: {
        children: {
          add: {
            click() {
              switch ( this.detail.code) {
                case 'SAL':
              
                  this.detail.visible = true
                  break
                case 'SALR':
              
                  this.detail.visible = true
                  break
                case 'OTH':
            
                  this.detail.visible = true
                  break
                case 'TRA':

                  this.detail.visible = true
              }
            },
          
          },
          save: {
            async click() {
              await this.form.update()
              return this.form.get()
            }
          },
          reverse: {
            async click() {
              await this.form.reverse()
              return this.form.get()
            }
          },
          commit: {
            async click() {
              await this.form.update()
              await this.form.audit({ addition: { id: this.form.data.id, additionalOperationCode: 'ADSI' } })
              return this.form.get()
            }
          },
          print: {
            click() {
              print(Purchase, { props: { data: this.form.data } })
            }
          },
          option: {
            async command(option: string) {
              switch (option) {
                case 'detail':
                  this.detail.visible = true
                  break
                case 'material':
                  this.material.visible = true
                  break
                case 'history':
                  this.history.visible = true
                  break
                case 'inject':
                  break
                case 'delete':
                  await EnMessageBox.confirm('是否确认删除单据', '提示')
                  await this.form.delete()
                  this.form.init()
                  this.refs.manifest.table.method()
                  break
                case 'export':
                  window.open(`/enocloud/sale/export/${this.form.data.id}`)
                  break
              }
            }
          }
        }
      },
      detailManifest: {
        computed: {
          code() {
            switch (this.code) {
              case 'SAL':
                return 'SALODLFD'
              case 'SALR':
                return 'SALRLFD'
              case 'OTH':
                return 'SALOTDLFD'
              case 'TRA':
                return 'STFOSEFD'
            }
          }
        }
      },
      manifest: {
        computed: {
          code() {
            switch (this.code) {
              case 'SAL':
                return 'SALOSEFD'
              case 'SALR':
                return 'SALRSEFD'
              case 'OTH':
                return 'SALOTSEFD'
              case 'TRA':
                return 'STFOFD'
            }
          }
        },
        children: {
          form: {
            data: {
              typeCode: '',
              businessStartDate: '',
              businessEndDate: '',
              quickSearch: '',
              goodsOem: '',
              goodsSerialNo: '',
              goodsName: '',
              outAuditStartDate: '',
              outAuditEndDate: '',
              invoiceTypeName: '',
              warehouseId: '',
              settlementProgressCode: '',
              startDate: '',
              endDate: '',
              goodsVehicleSpec: '',
              comment: '',
              warehouseName: '',
              preparedByName: '',
              settlingAccountMethodCode: '',
              stockInProgressCode: '',
              auditById: '',
              warehouse: '',
              plannedArrivalDate: '',
              saleGoodsAmount: 0
            }
          },
          row: {
            async click(row: EnocloudInventoryDefinitions['StockInDto']) {
              this.form.init()
              this.form.data.id = row.id
              await this.form.get()
           
            }
          }
        }
      },
      tabs: {
        active: 'goods'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/sale/:saleId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          salexport: {
            action: 'GET /enocloud/sale/export',
            loading: true,
            validate: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/sale',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/sale',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          delete: {
            action: 'DELETE /enocloud/sale/:saleId',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          close: {
            action: 'POST /enocloud/sale/:saleId/close',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          reverse: {
            action: 'POST /enocloud/sale/:saleId/reverse',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          audit: {
            action: 'POST /enocloud/sale/:saleId/audit',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A' || this.form.data.status?.code === 'S'
          },
          summary() {
            return this.form.data.saleGoods?.reduce(
              (res, item) => {
                res.count = calculator.add(res.cost, calculator.mul(item.count, item.goodsSpecification?.weight))

                res.primeCost = calculator.add(res.primeCost, calculator.mul(item.count, item.price))
                res.discountRate = res.postTaxAmount + res.postTaxAmount

                return res
              },
              {
                count: 0,

                primeCost: 0
              }
            )
          }
        },
        children: {
          saleGoods: {
            computed: {},
            add: {
              change(value: EnocloudCommonDefinitions['GoodsDto']) {
                this.form.data.saleGoods?.push({
                  goods: value,
                  goodsSpecification: value.specifications?.find((item) => item.defaultPurchase?.value) || value.specifications?.[0]
                })
              }
            },
            batch: {
              click() {
                this.select.visible = true
              }
            },
            delete: {
              click(index: number) {
                this.form.data.saleGoods?.splice(index, 1)
              }
            },
            editPrice: {
              click(row: EnocloudInventoryDefinitions['SaleGoodsDto']) {
                this.price.data = row.goods!
                this.price.specification = row.goodsSpecification!
                this.price.visible = true
              }
            },
            price: {
              change(row: EnocloudInventoryDefinitions['SaleGoodsDto']) {
                row.primeCost = calculator.div(row.price ?? 0, calculator.add(1, this.form.data.discountedAmount))
                row.count = calculator.mul(row.primeCost, row.count)
              }
            },
            amount: {
              change(value: number, row: EnocloudInventoryDefinitions['SaleGoodsDto']) {
                row.price = calculator.div(value, row.count ?? 0)
                this.form.saleGoods.price.change(row)
              }
            },
            primeCost: {
              change(row: EnocloudInventoryDefinitions['SaleGoodsDto']) {
                row.price = calculator.mul(row.primeCost, calculator.add(1, this.form.data.discountedAmount))
                this.form.saleGoods.price.change(row)
              }
            },
            preTaxAmount: {
              change(row: EnocloudInventoryDefinitions['SaleGoodsDto']) {
                row.price = calculator.div(calculator.mul(row.count, calculator.add(1, this.form.data.discountedAmount)), row.count ?? 0)
                this.form.saleGoods.price.change(row)
              }
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false,

        code: 'SAL' as 'SAL' | 'SALR' | 'OTH' | 'TRA',
        success(res: number | undefined) {
          if (!res) return
          if (this.code === 'SAL') {
            this.router.push('/accessory/stock/out/sale', (vm) => {
              vm.form.data.id = res
              vm.form.get()
            })
          } else if (this.code == 'SALR') {
            this.router.push('/accessory/stock/out/refund', (vm) => {
              vm.form.data.id = res
              vm.form.get()
            })
          } else if (this.code == 'OTH') {
            this.router.push('/accessory/stock/out/other', (vm) => {
              vm.form.data.id = res
              vm.form.get()
            })
          } else if (this.code == 'TRA') {
            this.router.push('/accessory/stock/out/transfer', (vm) => {
              vm.form.data.id = res
              vm.form.get()
            })
          }
        }
      },
      material: {
        visible: false
      },
      history: {
        visible: false
      },
      select: {
        visible: false,
        confirm(value: GoodsSelectionOption[]) {
          for (const item of value) {
            const exist = this.form.data.saleGoods?.find((p) => p.goods?.id === item.goods.id)

            if (exist) {
              exist.count = calculator.add(exist.count, item.count)
            } else {
              const init = {
                goods: item.goods,
                goodsSpecification: Object.assign(cloneDeep(item.goodsSpecification ?? {}), { goods: cloneDeep(item.goods) }),
                count: item.count,
                remainingInventoryCount: item.remainingInventoryCount,
                servicePrice: item.servicePrice,
                stockedOutCount: item.stockedOutCount,
                stockedCount: item.stockedCount,
                discountRate: item.discountRate,
                batch: item.batch
              } as unknown as EnocloudInventoryDefinitions['SaleGoodsDto']
              this.form.saleGoods.price.change(init)
              this.form.data.saleGoods?.push(init)
            }
          }
        }
      },
      price: {
        visible: false,
        data: null as EnocloudInventoryDefinitions['GoodsDto'] | null,
        specification: null as EnocloudInventoryDefinitions['GoodsSpecificationDto'] | null
      }
    }
  },

  mounted() {
    this.manifest.form.data.typeCode = this.code
  }
})
</script>
